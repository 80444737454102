<template lang="pug">
div(v-if='Navegador')
  //-Pc
  .d-none.d-md-block
    v-app-bar#navegador(  color='primario'  height='100' app).pr-5.elevation-0.navegador
      
        v-row(@click='dirigir_a("inicio")' style="cursor: pointer") 

          router-link(:to='{name: "inicio"}')
            v-img(contain :src='Navegador.logo' flat height=85 width='100').ml-1.elevation-0
          v-col
            v-container
              v-row
                h5(v-html='Navegador.titulo').white--text
              v-row  
                h6(v-html='Navegador.subtitulo').white--text
        v-spacer
        v-btn(icon  @click='drawer = !drawer' dark)
          v-icon(color='azul' large) fas fa-bars
        //-.columna-rutas
          .ruta(v-for='{texto, ruta}, i in Navegador.rutas' :key='i')
            button(:seleccionado='ruta=== ruta_actual' @click='dirigir_a(ruta)') 
              span.azul--text.font-weight-bold {{texto}}
        .columna-redes
          .red-social(v-for='{icono, enlace}, i in Navegador.redes_sociales' :key='i' @click='abrir_enlace(enlace)')
            img(:src='icono').pointer.ml-4
    v-navigation-drawer( temporary v-model="drawer" app width='240').secundario
      v-list
        v-list-item
          router-link(:to='{name: "inicio"}')
            v-img( height=185 width=200 :src='Navegador.logo' ).justify-center
          v-list-item-content
        v-list-item.pa-5
          v-container.pa-5.justify-center
            v-row.justify-center
              h5(v-html='Navegador.titulo').white--text
            v-row.justify-center
              h6(v-html='Navegador.subtitulo').white--text.font-weight-light
        v-list-item(v-for='{texto, ruta, icono}, i in Navegador.rutas' :key='i' 
        @click='ruta_actual!=ruta ? dirigir_a(ruta) : drawer=false').my-1
            v-row.align-items-center.ml-3
              v-icon(:style='ruta_actual===ruta ? "color: #050510" : "color: white" ') {{icono}}
              span(v-html='texto' :style='ruta_actual===ruta ? "color: #050510" : "color: white"').font-weight-light.mt-4.ml-3
  .d-md-none
    v-app-bar( app   v-if='Navegador'  color='primario'   height='100' ).pr-5
      router-link(:to='{name: "inicio"}')
        v-img(contain :src='Navegador.logo'  height=60 width=110).elevation-0
      v-spacer
      v-btn(icon  @click='drawer = !drawer' dark)
        v-icon(color='azul' large) fas fa-bars
    v-navigation-drawer( temporary v-model="drawer" app).secundario
      v-list
        v-list-item.justify-center
          router-link(:to='{name: "inicio"}')
            v-img( height=185 width=200 :src='Navegador.logo' contain).justify-center
        v-list-item.pa-5
          v-container.pa-5.justify-center
            v-row.justify-center
              h5(v-html='Navegador.titulo').white--text
            v-row.justify-center
              h6(v-html='Navegador.subtitulo').white--text.font-weight-light
        v-list-item(v-for='{texto, ruta, icono}, i in Navegador.rutas' :key='i' 
        @click='ruta_actual!=ruta ? dirigir_a(ruta) : drawer=false').my-1
            v-row.align-items-center.ml-3
              v-icon(:style='ruta_actual===ruta ? "color: #050510" : "color: white" ') {{icono}}
              span(v-html='texto' :style='ruta_actual===ruta ? "color: #050510" : "color: white"').font-weight-light.mt-4.ml-3
        
</template>
<script>
export default {
  data: ()=>({
    drawer: false,
  }),
  computed: {
    ruta_actual(){
      return this.$route.name
    },
  },
  methods: {
    abrir_enlace(enlace){
      window.open(enlace, '_blank')
    }
  },
}
</script>
<style lang="sass" scoped>
.navegador
  position: sticky
.fila-elementos
  display: flex
  flex-direction: row
  .columna-bars
    display: flex
    flex-direction: row
    margin-left: 1205px
    
  .columna-redes
    display: flex
    flex-direction: row
    padding-left: 45px
    padding-top: 13px
    .red-social
      img
        width: 23px
        cursor: pointer
</style>